import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { getBuildIdQuery, IMegaMenuVisibilitySettings, ISettingsResponse } from '@ncg/data';
import { FeatureDetectionService, HeaderService, NavigationService, ScrollStatusService, SettingsService, STATUS } from '@ncg/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-header',
    template: `
        <div class="header__backdrop" [ngClass]="{ 'header__backdrop--is-open': isMegaMenuOverlayVisible }"></div>
        <div class="header__wrapper" [style.height]="headerHeight + 'px'">
            <header
                #header
                class="header"
                [ngClass]="{ 'header--is-sticky': isFixed }"
                [style.transform]="hideNavBar ? 'translate3d(0, ' + -headerHeight + 'px, 0)' : ''"
            >
                <ncg-top-menu class="is-hidden-touch" *ngIf="hasTopMenu" [links]="settings?.topMenuLinks"></ncg-top-menu>
                <!-- Meta menu -->
                <div class="header__meta-menu-wrapper">
                    <ncg-meta-menu class="container is-fullwidth header__meta-menu is-flex is-justify-content-flex-end"></ncg-meta-menu>
                </div>
                <div class="header__container is-fullwidth">
                    <div class="columns is-mobile is-justify-content-space-between">
                        <!-- Mobile Menu -->
                        <div class="header__mobile-menu column is-narrow border-right is-hidden-desktop">
                            <button
                                ncgMobileMenuTrigger
                                [hasMobileHeader]="false"
                                (status)="onMobileStatusChange($event)"
                                type="button"
                                class="button is-clean header__mobile-button"
                                aria-label="menu"
                                aria-expanded="false"
                            >
                                <ng-container *ngIf="isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'close_light'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        class="is-flex"
                                        classes=""
                                    ></svg-icon-sprite>
                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.close' | translate }}</div>
                                </ng-container>

                                <ng-container *ngIf="!isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'nav'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        class="mobile-menu__nav-icon"
                                        classes=""
                                    ></svg-icon-sprite>
                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.menu' | translate }}</div>
                                </ng-container>
                            </button>
                        </div>

                        <div class="header__logo column is-narrow-desktop is-flex">
                            <ng-container *ngIf="settings?.logoLink?.url && settings?.logoLink?.isExternal; else default">
                                <a class="logo" [href]="settings?.logoLink?.url" [attr.target]="settings?.logoLink?.target">
                                    <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                                </a>
                            </ng-container>

                            <ng-template #default>
                                <a class="logo" [routerLink]="['/']">
                                    <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                                </a>
                            </ng-template>
                        </div>

                        <!-- Desktop Menu -->
                        <ncg-main-menu
                            (isMegaMenuVisibleSettings)="onMegaMenuChange($event)"
                            [isFirstLevelMenu]="false"
                            class="header__main-menu column is-hidden-touch is-narrow is-flex level"
                        ></ncg-main-menu>
                    </div>
                </div>
            </header>
        </div>
        <ng-template #logoSymbolTmpl>
            <img *ngIf="!isMc" [src]="'/assets/images/logo-car.svg' + buildIdQuery" alt="Honda" width="130" height="18" />
            <img *ngIf="isMc" [src]="'/assets/images/logo-mc.svg' + buildIdQuery" alt="Honda" width="134" height="30" />
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly HAS_TOP_MENU_KEY = makeStateKey<boolean>('hasTopMenu');

    @ViewChild('header') private readonly headerElement: ElementRef;

    settings?: ISettingsResponse;
    isMobileMenuOpen = false;
    isMegaMenuOverlayVisible = false;
    isFixed: boolean;
    previousPosition: number;
    hideNavBar = false;
    headerHeight: number;
    currentPosition: number;
    buildIdQuery = getBuildIdQuery();
    isMc = false;
    hasTopMenu: boolean;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly transferState: TransferState,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly settingsService: SettingsService,
        private readonly navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.navigationService.breadcrumb$.pipe(takeUntil(this.unsubscribe)).subscribe((breadcrumb) => {
            this.isMc = (breadcrumb && breadcrumb.length > 1 && breadcrumb[1].value === '/mc') || false;
            this.cd.markForCheck();
        });

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.settings = settings;
                this.hasTopMenu = settings.hasTopMenu;

                if (this.featureDetection.isServer()) {
                    this.transferState.set(this.HAS_TOP_MENU_KEY, this.hasTopMenu);
                }

                this.setInitialValues();
            });

        this.scrollStatusService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                this.currentPosition = position;
                this.handleHeaderPosition();
            });

        if (this.featureDetection.isBrowser()) {
            this.navigationService
                .isFrontPage()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    // Makes sure the header is visible or hidden, before doing calculations
                    this.setInitialValues();
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetection.isBrowser() && this.headerElement) {
            this.headerHeight = this.headerElement.nativeElement.offsetHeight;
            this.previousPosition = this.headerHeight;
            this.headerService.updateModelPageHeader(this.headerHeight);
        }
        this.cd.markForCheck();
    }

    handleHeaderPosition() {
        // Alway set a fixed header, when mobile nav is open.
        if (this.isMobileMenuOpen) {
            this.isFixed = true;
            this.hideNavBar = false;
            this.cd.markForCheck();
            return;
        }

        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cd.markForCheck();
    }

    onMobileStatusChange(status: STATUS) {
        this.isMobileMenuOpen = status === 0;
        if (this.isMobileMenuOpen) {
            this.handleHeaderPosition();
        }
    }

    onMegaMenuChange(settings: IMegaMenuVisibilitySettings) {
        this.isMegaMenuOverlayVisible = settings.isVisible;
        this.cd.detectChanges();
    }
}
